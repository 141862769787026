@font-face {
  font-family: "Proxima Nova Lt";
  src: url("../src/assets/fonts/90b3680786db32f9b5c112d1537677db.eot");
  src: url("../src/assets/fonts/90b3680786db32f9b5c112d1537677db.eot?#iefix") format("embedded-opentype"), url("../src/assets/fonts/90b3680786db32f9b5c112d1537677db.woff2") format("woff2"), url("../src/assets/fonts/90b3680786db32f9b5c112d1537677db.woff") format("woff"), url("../src/assets/fonts/90b3680786db32f9b5c112d1537677db.ttf") format("truetype");
}


body {
  margin: 0;
  width: 100%;
  height: auto;
  background: url("assets/img/bg-masthead.jpg");
  height: 100%;
  background-position: center;
  background-size: cover;
}


.bgimg {
  font-family: "Proxima Nova Lt";
  font-size: 25px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.topleft {
  position: absolute;
  top: 0;
  left: 16px;
}

.bottomleft {
  position: absolute;
  bottom: 15px;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.bottomleft h2 {

  font-family: "Proxima Nova Lt";
  font-weight: 800;
  color: #002a54;
  font-size: 25px;
}


.middle {
  top: 10%;
  position: absolute;
  width: 100%;
}

.banner-text {
  font-size: 30px;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.title_span {
  font-weight: 400;
  /* line-height: 70px;*/
}

.bottom-text h2,
.title_span {
  color: #002a54;
  font-family: Proxima Nova Lt;
}

.underline {
  border-bottom: 1px solid #002a54;
  border-top: 1px solid #002a54;
  padding-bottom: 2px;
  padding-top: 4px;
}

.btn-lg {
  border: 2px solid #002a54;
  border-radius: 0;
  font-family: Proxima Nova Lt;
  font-size: 25px;
  font-weight: 800;
  letter-spacing: 2px;
  padding: 1.4rem 2rem;
}

.btn-primary {
  background-color: #dddddd38;
  border-color: #002a54;
  color: #002a54;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #fff;
  border-color: #002a54;
  color: #002a54;
}


.portfolio-modal .btn-close {
  color: #1abc9c;
  font-size: 10px;
  padding: 1rem;
}

.portfolio-modal .portfolio-modal-title {
  font-size: 2.25rem;
  line-height: 2rem;
}

/*Model */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 5px solid #b7b6b6;
  border-radius: 0;
  outline: 0;
  margin: 0 auto;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
}

.modal-title {
  margin-bottom: -13px;
  width: 100%;
  font-family: "Proxima Nova Lt";
  font-weight: 600;
  color: #002a54;
  font-size: 30px;
  letter-spacing: 4.5px;
  margin-top: 1rem;
  text-align: center;
}

.btn-footer {
  text-align: center;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }

  .masthead {
    height: 70vh;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-content {
    width: 100%;
  }

  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-title {
    font-size: 25px;
  }
}

@media (max-width: 767.98px) {

  .modal-content {
    width: 100%;
  }

  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-title {
    font-size: 25px;
  }
}


@media (max-width: 991.98px) {
  .modal-title {
    font-size: 25px;
  }

  .modal-content {
    width: 100%;
  }

  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.row {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

/*Close modal*/


.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  cursor: pointer;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #b7b6b6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 42%;
  padding: 10px;
}

.column1 {
  width: 88%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 80%;
  }

  .column1 {
    width: 80%;
  }
}

label {
  float: left;
  color: #002a54;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

textarea.form-control.text-area {
  height: 120px;
}

button.btn.banner-btn2 {
  background: #002a54;
  color: #fff;
  padding: 0.6rem 2rem;
  font-size: 17px;
  border-radius: 0;
  border: 0px solid #002a54;
  font-family: "Proxima Nova Lt";
  letter-spacing: 2px;
  cursor: pointer;
}

button.btn.banner-btn2:hover {
  background: #ffffff;
  color: #002a54;
  padding: 0.6rem 2rem;
  font-size: 17px;
  border-radius: 0;
  border: 1px solid #002a54;
  font-family: "Proxima Nova Lt";
  letter-spacing: 2px;
}

p#remainingC {
  text-align: right;
  margin-top: -1rem;
}



/* Mobile Responsiveness */

@media (min-width:320px) {

  /* smartphones, iPhone, portrait 480x320 phones */
  .img-fuild {
    width: 70%;
  }

  .middle {
    top: 20%;
    position: absolute;
    width: 100%;
  }

  .banner-text {
    font-size: 14px;
    margin-bottom: 2rem;
  }

  .btn-lg {
    font-size: 20px;
    padding: 0.6rem 1rem;
  }


  .banner-text {
    font-size: 14px;
    margin-bottom: 2rem;
  }

  .btn-lg {
    font-size: 15px;
    padding: 0.6rem 1rem;
  }

  .bottomleft h2 {
    font-size: 12px;
  }

  .modal {
    width: 98%;
  }

  /*Modal */

}


@media (min-width:481px) {

  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .img-fuild {
    width: 50%;
  }

  .middle {
    top: 20%;
    position: absolute;
    width: 100%;
  }

  .banner-text {
    font-size: 15px;
    margin-bottom: 2rem;
  }

  .btn-lg {
    font-size: 20px;
    padding: 0.6rem 1rem;
  }


  .middle {
    top: 10%;
    position: absolute;
    width: 100%;
  }

  .banner-text {
    font-size: 15px;
    margin-bottom: 2rem;
  }

  .btn-lg {
    font-size: 20px;
    padding: 0.6rem 1rem;
  }

  .bottomleft h2 {
    font-size: 12px;
  }
}


@media (min-width:641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */

  .img-fuild {
    width: 30%;
  }

  .middle {
    top: 10%;
    position: absolute;
    width: 100%;
  }

  .banner-text {
    font-size: 15px;
    margin-bottom: 2rem;
  }

  .btn-lg {
    font-size: 18px;
    padding: 0.9rem 1.2rem;
  }

  .bottomleft {
    bottom: 13px;
  }
}



@media only screen and (min-width: 992px) {

  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .img-fuild {
    width: 35%;
  }

  .banner-text {
    font-size: 20px;
    margin-bottom: 2rem;
  }

  .middle { 
    top: 20%;
    position: absolute;
    width: 100%;
  }
  .btn-lg {
    font-size: 20px;
    padding: 0.6rem 1.6rem;
  }

  .bottomleft h2 {
    font-size: 15px;
  }

  .modal-content {
    width: 80%;
  }
}


@media (min-width:1025px) {
/* big landscape tablets, laptops, and desktops */
.img-fuild {
  width: 30%;
}

.btn-lg {
  font-size: 18px;
  padding: 0.6rem 1.2rem
}

.banner-text {
  font-size: 18px;
  margin-bottom: 2rem;
}

.modal-content {
  width: 70%;
}

.bottomleft h2 {
  font-size: 15px;
}
}


@media (min-width:1282px) {

  /* hi-res laptops and desktops */
  .img-fuild {
    width: auto;
  }

  .modal-content {
    width: 50%;
  }


  .middle {
    top: 15%;
    position: absolute;
    width: 100%;
  }

  .banner-text {
    font-size: 28px;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  .bottomleft h2 {
    font-size: 20px;
  }

  .btn-lg {
    border: 2px solid #002a54;
    border-radius: 0;
    font-family: Proxima Nova Lt;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 2px;
    padding: 1.4rem 2rem;
  }

}


@media (min-width:1380px) {

  /* hi-res laptops and desktops */
  .img-fuild {
    width: auto;
  }

  .modal-content {
    width: 50%;
  }


  .middle {
    top: 15%;
    position: absolute;
    width: 100%;
  }

  .banner-text {
    font-size: 30px;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  .bottomleft h2 {
    font-size: 25px;
  }

  .btn-lg {
    border: 2px solid #002a54;
    border-radius: 0;
    font-family: Proxima Nova Lt;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 2px;
    padding: 1.4rem 2rem;
  }

}


@media only screen and (min-width: 1440px) {

  /* hi-res laptops and desktops */

  .img-fuild {
    width: auto;
  }

  .modal-content {
    width: 50%;
  }

 
  .middle {
    top: 20%;
    position: absolute;
    width: 100%;
  }

  .banner-text {
    font-size: 30px;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  .bottomleft h2 {
    font-size: 25px;
  }

  .btn-lg {
    border: 2px solid #002a54;
    border-radius: 0;
    font-family: Proxima Nova Lt;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 2px;
    padding: 1.4rem 2rem;
  }

}

@media only screen and (min-width: 1920px) {

 /* hi-res laptops and desktops */
 .img-fuild {
  width: auto;
}

.modal-content {
  width: 50%;
}

.middle {
  top: 20%;
  position: absolute;
  width: 100%;
}
.banner-text {
  font-size: 30px;
  margin-top: 1rem;
  margin-bottom: 4rem;
}


.btn-lg {
  border-radius: 0;
  font-family: Proxima Nova Lt;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 2px;
  padding: 1.2rem 2rem;
}

}



@media  only screen and (min-width: 2200px) and (max-width: 2560px)  {

  /* hi-res laptops and desktops */
  .img-fuild {
   width: auto;
 }
 
 .modal-content {
   width: 50%;
 }
 
 .middle {
   top: 20%;
   position: absolute;
   width: 100%;
 }
 .banner-text {
   font-size: 30px;
   margin-top: 1rem;
   margin-bottom: 4rem;
 }
 
 
 .btn-lg {
   border-radius: 0;
   font-family: Proxima Nova Lt;
   font-size: 30px;
   font-weight: 800;
   letter-spacing: 2px;
   padding: 1.2rem 2rem;
 }
 
 }